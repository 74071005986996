<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="70%"
      title="发票详情"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <h3 class="mt-3 title-bg">充值记录</h3>
      <base-table
        ref="invoiceRef"
        rowKey="invoiceTableRowKey"
        :columnsData="invoiceColumns"
        :tableData="invoiceTableData"
      >
      </base-table>

      <div class="footer">
        <h3 class="mt-3 title-bg">用户申请发票信息</h3>
        <a-form-model
          layout="horizontal"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
        >
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="申请金额">{{ invoiceInfo.amount }} 元</a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                label="邮箱">{{ invoiceInfo.email }}</a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                label="状态" v-if="invoiceData.audit_status == 1">未开票</a-form-model-item>
              <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                label="状态" v-if="invoiceData.audit_status == 2">已开票</a-form-model-item>
            </a-col>
          </a-row>

          <!-- 企业 -->
          <template v-if="invoiceInfo.type==1">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">企业</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="抬头">{{invoiceInfo.name}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="税号">{{invoiceInfo.corporate_duty_paragraph}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="6">
                <a-form-model-item label="电话">{{ invoiceInfo.phone }}</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="开户行">{{invoiceInfo.open_bank}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="账号">{{invoiceInfo.bank_account}}</a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{span:2}" :wrapperCol="{span:22}" label="注册地址">{{invoiceInfo.registered_address}}</a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 个人 -->
          <template v-if="invoiceInfo.type==2">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">个人</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="抬头">{{invoiceInfo.name}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="电话">{{invoiceInfo.phone}}</a-form-model-item>
              </a-col>
            </a-row>
          </template>
          
          <!-- 财务处理 -->
          <template v-if="invoiceData.audit_status == 2">
            <h3 class="mt-3 title-bg">财务开票处理</h3>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{ span: 2 }" label="上传发票">
                  <img
                    class="img-class"
                    :src="item.file_url"
                    alt
                    v-for="(item, index) in imgList"
                    :key="index"
                    @click="handlerBigImg(item)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :span="24">
                <a-form-model-item :labelCol="{span:2}" label="开票备注">{{ invoiceData.audit_remarks }}</a-form-model-item>
              </a-col>
            </a-row>
          </template>
          
        </a-form-model>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="发票图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="handlerCancel"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import {
  getTopupDesc,
  // openInvoce,
} from "@/api/trading-manage/topup-invoice.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: Number,
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      form: {},
      imgList: [],
      invoiceData: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      invoiceTableData: [],
      invoiceColumns: [
        {
          title: "订单号",
          dataIndex: "order_no",
          align: "center",
        },
        {
          title: "产生时间",
          dataIndex: "generation_time",
          align: "center",
        },
        {
          title: "面额",
          dataIndex: "denomination",
          align: "center",
          width: "90px",
        },
        {
          title: "支付金额",
          dataIndex: "price",
          align: "center",
          width: "90px",
        },
      ],
      invoiceInfo: {},
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getTopupDesc({ id: this.requestID })
      if (code == 0) {
        this.invoiceData = data
        this.invoiceTableData = data.recharge_order_list
        this.invoiceTableData.map((item, index) => {
          item["invoiceTableRowKey"] = index
          return item
        })
        this.invoiceInfo = data.invoice_info
        this.imgList = data.invoice_info.invoice_files
      }
    },
    handlerBigImg(e) {
      this.previewImage = e.file_url
      this.previewVisible = true
    },
    handlerCancel() {
      this.previewVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.preview-class /deep/ .ant-modal-body {
  padding: 10px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}

.footer {
  margin-top: 20px;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>
