<template>
  <div>
    <a-modal :visible.sync="show"
             width="70%"
             title="上传发票"
             okText="提交发票 （发送到用户邮箱）"
             cancelText="取消"
             @ok="handlerOK"
             @cancel="$emit('update:show', false)">
      <h3 class="mt-3 title-bg">充值记录</h3>
      <base-table
          ref="invoiceRef"
          rowKey="invoiceTableRowKey"
          :columnsData="invoiceColumns"
          :tableData="invoiceTableData">
      </base-table>

      <div class="footer">
        <h3 class="mt-3 title-bg">用户申请发票信息</h3>
        <a-form-model layout="horizontal"
                      :model="invoiceInfo"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="formRef"
                      :rules="rules">
          <a-row>
            <a-col :span="6">
              <a-form-model-item :labelCol="{span:8}" :wrapperCol="{span:16}"
                label="申请金额">{{ invoiceInfo.amount }} 元</a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                prop="email" label="邮箱">
                <a-input v-model="invoiceInfo.email"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 企业 -->
          <template v-if="invoiceInfo.type==1">
            <a-row>
              <a-col :span="6">
                <a-form-model-item :labelCol="{span:8}" :wrapperCol="{span:16}" label="抬头类型">
                  <a-radio-group v-model="invoiceInfo.type">
                    <a-radio :value="1">企业</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" label="抬头">
                  <a-input v-model="invoiceInfo.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" label="税号">
                  <a-input v-model="invoiceInfo.corporate_duty_paragraph"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="6">
                <a-form-model-item :labelCol="{span:8}" :wrapperCol="{span:16}" label="电话">
                  <a-input v-model="invoiceInfo.phone"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="开户行">
                  <a-input v-model="invoiceInfo.open_bank"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="账号">
                  <a-input v-model="invoiceInfo.bank_account"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{span:2}" :wrapperCol="{span:22}" label="注册地址">
                  <a-input v-model="invoiceInfo.registered_address"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 个人 -->
          <template v-if="invoiceInfo.type==2">
            <a-row>
              <a-col :span="6">
                <a-form-model-item :labelCol="{span:8}" :wrapperCol="{span:16}" label="抬头类型">
                  <a-radio-group v-model="invoiceInfo.type">
                    <a-radio :value="1">企业</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" label="抬头">
                  <a-input v-model="invoiceInfo.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="电话" prop="phone">
                  <a-input v-model="invoiceInfo.phone"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>
          
          <h3 class="mt-3 title-bg">财务开票处理</h3>
          <!-- 上传发票 -->
          <a-row class="mt-2">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" 
                label="上传发票" class="upload-class">
                <uploadImage type="20" multiple :value.sync="invoiceInfo.invoice_files" @getList="(list)=>{invoiceInfo.invoice_files = list}" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row class="mb-2">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="开票备注">
                <a-input type="textareas" v-model="invoiceInfo.audit_remarks"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import uploadImage from "@/components/upload-image"

import {
  getTopupDesc,
  openTopup,
} from "@/api/trading-manage/topup-invoice.js"
export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: Number,
    },
    selectKeys: {
      type: Array,
    },
  },
  data () {
    // let phoneCheck = (rule, value, callback) => {
    //   const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    //   if (!reg.test(value)) {
    //     callback("手机号码格式有误")
    //     return
    //   } else {
    //     callback()
    //   }
    // }
    let emailCheck = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (!reg.test(value)) {
        callback("邮箱格式有误")
        return
      } else {
        callback()
      }
    }
    return {
      rules: {
        // phone: [{ validator: phoneCheck, trigger: "blur" }],
        email: [{ validator: emailCheck, trigger: "blur" }],
      },
      form: {},
      imgList: [],
      invoiceData: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      invoiceTableData: [],
      invoiceColumns: [
        {
          title: "订单号",
          dataIndex: "order_no",
          align: "center",
        },
        {
          title: "充值时间",
          dataIndex: "generation_time",
          align: "center",
        },
        {
          title: "面额",
          dataIndex: "denomination",
          align: "center",
          width: "90px",
        },
        {
          title: "支付金额",
          dataIndex: "price",
          align: "center",
          width: "90px",
        },
      ],
      invoiceInfo: {},
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { code, data } = await getTopupDesc({ id: this.requestID })
      if (code == 0) {
        this.invoiceData = data
        this.invoiceTableData = data.recharge_order_list
        this.invoiceTableData.map((item, index) => {
          item["invoiceTableRowKey"] = index
          return item
        })
        this.invoiceInfo = data.invoice_info
        // this.imgList = data.invoice_info.invoice_files
      }
    },
    handlerOK () {
      const params = this.invoiceInfo
      params.file_paths = params.invoice_files.join(",")
      params.id = this.requestID
      openTopup(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("开票成功")
          this.$emit("update:show", false)
        }
      })
    },
    handlerCancel () {
      this.$emit("update:show", false)
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  margin-bottom: 10px;
}
.footer {
  margin-top: 20px;
  .img-class {
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
  }
}
.ant-form-item {
  margin-bottom: 0;
}

/deep/ .upload-class .ant-upload-picture-card-wrapper {
  margin-top: 10px;
}
</style>