import ajax from "@/utils/ajax.js";

/**
 * 获取发票列表
 * @param {*} params
 * @returns
 */
export function getTopupList(params) {
  return ajax.post("/RechargeInvoiceApply/getList", params);
}

/**
 * 获取发票详情
 * @param {*} params
 * @returns
 */
export function getTopupDesc(params) {
  return ajax.post("/RechargeInvoiceApply/detail", params);
}

/**
 * 开发票
 * @param {*} params
 * @returns
 */
export function openTopup(params) {
  return ajax.post("/RechargeInvoiceApply/open", params);
}
